@import url("https://fonts.googleapis.com/css?family=Lato|Montserrat|Open+Sans|Patua+One|Bree+Serif|Google+Sans|Varela+Round|Roboto|Roboto+Slab|Poppins&display=swap");
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@font-face {
  font-family: "gilroymedium";
  src: url("assets/fonts/gilroy-medium-webfont.woff2") format("woff2"), url("assets/fonts/gilroy-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National 2";
  src: url("assets/fonts/National2Condensed-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National 2";
  src: url("assets/fonts/National2Condensed-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "National 2";
  src: url("assets/fonts/National2Condensed-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "National 2";
  src: url("assets/fonts/National2Condensed-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Sentinel Office";
  src: url("assets/fonts/SentinelOffice-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sentinel Office";
  src: url("assets/fonts/SentinelOffice-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Sentinel Office";
  src: url("assets/fonts/SentinelOffice-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Sentinel Office";
  src: url("assets/fonts/SentinelOffice-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
*::-webkit-input-placeholder {
  color: #bbb !important;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #bbb !important;
  opacity: 1;
  height: we;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #bbb !important;
  opacity: 1;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #bbb !important;
}

*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb !important;
}

*::placeholder {
  /* modern browser */
  color: #bbb !important;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: inherit;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: inherit;
  transition: background-color 5000s ease-in-out 0s;
  font-size: inherit;
}

body > canvas {
  max-width: 100%;
}

:root {
  --titleFont: "Bree Serif", serif;
  --titleWeight: Bold;
  --titleSize: 30px;
  --bodyFont: "Varela Round", sans-serif;
  --bodyWeight: Normal;
  --bodySize: 18px;
  --questionColor: white;
  --primaryColor: $color1;
  --backgroundColor: $color3;
  --questionBackgroundColor: rgba(32, 36, 48, 0.95);
  --answerColor: white;
  --answerBackgroundColor: $color1;
}

.MuiInputBase-root {
  font-family: inherit !important;
}

#emoji-close-div {
  display: none !important;
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 20px !important;
    padding-right: 24px;
  }
}
#navLogo {
  margin-right: 30px;
}
#navLogo #navLogoImg {
  padding-top: 6px;
  padding-right: 10px;
  height: 36px;
}
#navLogo #navLogoAdeptly {
  float: right;
  color: white;
  font-size: 20px;
  font-family: "gilroymedium", sans-serif;
  margin-top: 6px;
}

.primaryColor {
  color: var(--primaryColor);
}

.link {
  color: #137ae7 !important;
}

#boxedContainer {
  max-width: 900px;
  margin: auto;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #12c4c4 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #12c4c4 !important;
}

html {
  font-size: 16px !important;
  font-family: "Varela Round", sans-serif;
}

.progress {
  width: 100%;
  /* margin-top: 30px; */
  height: 15px !important;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  background-image: none !important;
}
.progress .progress-bar-info {
  background-color: #12c4c4;
}

.progress-static {
  position: relative !important;
  display: block !important;
}

p {
  font-family: "Varela Round", sans-serif;
  margin: 0px;
  font-size: 16px;
  line-height: 26px;
}

a {
  text-decoration: unset;
}

.tooltip {
  font-size: 16px !important;
  font-family: "Varela Round", sans-serif !important;
  font-weight: 400 !important;
}

#root {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.form-control {
  min-height: 40px;
}

a:hover {
  text-decoration: unset;
}

.whiteButton,
.whiteDropdown {
  max-width: 180px;
  border-radius: 0 !important;
  background-color: #fff !important;
  color: #2d3446 !important;
  border: 1px solid #e9e9e9 !important;
}

.ql-tooltip {
  z-index: 1;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.highlightOnHover:hover {
  color: #1B68FF !important;
}

.transparentBtn {
  font-size: 18px !important;
  letter-spacing: 1px;
  padding: 14px 38px 14px 38px !important;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 10px !important;
  background: none;
  background-color: transparent !important;
  border: solid 2px white !important;
  color: white !important;
  border-radius: 5px !important;
  font-family: "Varela Round", sans-serif !important;
  text-transform: none !important;
  font-weight: 500 !important;
}

.transparentBtn:hover {
  border: solid 2px #dfdfdf !important;
}

h2 {
  font-weight: 400;
  font-family: "Bree Serif", serif;
  margin-top: 10px !important;
}

h6 {
  font-family: "Varela Round", sans-serif !important;
  font-size: 22px !important;
}

button:focus {
  box-shadow: none !important;
}

.outlinedTextfieldContainer {
  margin: 20px 5px 20px 5px !important;
}
.outlinedTextfieldContainer .outlinedTextfield .MuiOutlinedInput-notchedOutline {
  border: none;
}

#Overlay {
  height: 100%;
  width: 100%;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: absolute;
  top: 0;
}
#Overlay img {
  position: absolute;
  height: 100%;
  width: 100%;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
@media only screen and (max-width: 600px) {
  #Overlay img {
    object-fit: cover !important;
  }
}
#Overlay .CrossfadeImage {
  height: 100%;
  width: 100%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  max-width: none !important;
  max-width: none !important;
}
#Overlay .CrossfadeImage img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  height: 100%;
  width: 100%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}
@media only screen and (max-width: 600px) {
  #Overlay .CrossfadeImage img {
    object-fit: cover !important;
  }
}

.MuiButton-contained.Mui-disabled {
  color: rgba(255, 255, 255, 0.26) !important;
  background-color: rgba(19, 122, 231, 0.75) !important;
}

.ql-size-large {
  font-family: var(--titleFont);
  font-size: 30px;
  font-weight: normal;
}
@media (min-width: 600px) {
  .ql-size-large {
    font-weight: var(--titleWeight);
    font-size: var(--titleSize) !important;
  }
}

#header {
  display: inline-block;
  width: 100%;
  width: -webkit-fill-available;
}

.pageHeader {
  height: 40px;
  color: #788195;
  border-left: 4px solid #e4e6e9;
  padding: 0px 10px 2px 10px;
  font-size: 26px !important;
  margin-bottom: 24px !important;
  margin-top: 24px !important;
  float: left;
}
.pageHeader form {
  margin-top: -7px;
}

.topNav {
  float: right;
  margin-top: 5px;
}
.topNav .MuiButton-root {
  margin-left: 10px;
}

.containedHeader {
  padding-left: 0px;
  border-left: 0px;
}

.MuiCircularProgress-colorPrimary {
  color: #137ae7 !important;
}

.pageContainer {
  background-color: #f1f3f6;
  width: 100%;
  height: 100%;
  left: 0px;
  padding-left: 100px;
  overflow: scroll;
  padding-right: 30px;
  padding-top: 90px;
  padding-bottom: 40px;
}
.pageContainer .gameLoaderSpinner {
  color: var(--primaryColor);
  display: block;
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 100px);
  text-align: center;
  width: 200px;
  height: 200px;
}
.pageContainer .MuiCircularProgress-colorPrimary {
  color: #137ae7;
  display: block;
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
.pageContainer .addPageInput .MuiFilledInput-root {
  border-radius: 3px;
  background-color: white;
  border: 1px solid #e9e9e9;
}
.pageContainer .addPageInput .MuiInputLabel-filled {
  color: #a1a1a1;
}
.pageContainer .addPageInput:hover {
  border-color: #F21059;
}

@media only screen and (max-width: 600px) {
  .pageContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.button-blue {
  background-color: #10aeae !important;
  border: 1px solid #10aeae !important;
  color: white !important;
  text-shadow: none !important;
  border-radius: 5px !important;
  font-family: "Varela Round", sans-serif !important;
  text-transform: none !important;
  font-weight: 500 !important;
}

button {
  outline: none !important;
}

button:focus {
  outline: none !important;
}

button:hover {
  outline: none !important;
}

.errorDialog {
  background-image: linear-gradient(180deg, #f2dede 0, #e7c3c3);
  border-color: #dca7a7;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #a94442;
  background-color: #f2dede;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  margin: auto;
}

.btn-default {
  background-image: none !important;
  color: white;
  text-shadow: none !important;
  padding: 10px 15px 10px 15px;
  text-shadow: none;
  font-family: "Varela Round", sans-serif;
  border-radius: 0px;
  background-color: white;
  color: #2d3446;
  border: 1px solid #e9e9e9;
}

textarea,
input {
  font-family: "Varela Round", sans-serif !important;
}

.app {
  height: 100%;
}
.app #landingPageContainer {
  height: 100%;
}
.app #projectAuthContainer {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#storyViewerPanel .dropdown {
  width: 100%;
  text-align: left;
}

.chapterDropdown {
  text-align: left !important;
  border-radius: 0px !important;
  padding-left: 25px !important;
  font-size: 20px !important;
  width: 100%;
}

.storyPanel,
.nav,
.editBtn {
  margin: 20px;
}
.storyPanel .panel,
.nav .panel,
.editBtn .panel {
  border: none;
  background-color: #f1f1f1;
}

.closePanelBtn {
  margin: 12px;
  position: absolute;
  right: -70px;
}

#addPageCornerBtn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  margin: 0px !important;
}

.addNewPage {
  margin-left: 20px;
  margin-bottom: 20px !important;
}

#glowContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.MuiButton-root {
  line-height: inherit !important;
}

#AdeptlyLogo {
  position: fixed;
  z-index: 1;
  opacity: 0.6;
}
#AdeptlyLogo img {
  width: 80px;
}
@media (max-width: 1000px) {
  #AdeptlyLogo {
    display: none;
  }
}

.topLeft {
  left: 20px;
  top: 20px;
}

.topRight {
  right: 20px;
  top: 20px;
}

.bottomLeft {
  left: 20px;
  bottom: 20px;
}

.bottomRight {
  right: 20px;
  bottom: 20px;
}

#chapterButtons {
  display: flex;
  align-items: flex-end;
  float: right;
  margin-left: 15px;
}
#chapterButtons .chapterSelectionDropdown {
  border-radius: 0px !important;
  background-color: white !important;
  color: #2d3446 !important;
  border: 1px solid #e9e9e9 !important;
}
#chapterButtons .editChapterBtn {
  border-radius: 0px !important;
  color: #6a6c6a;
  background-color: white !important;
  border: 1px solid #e9e9e9 !important;
}

.gameLoaderContainer {
  background-color: var(--backgroundColor) !important;
}

table.MuiTable-root > tbody > tr:nth-child(1) > td:nth-child(2) > h6 {
  font-size: 0.875rem !important;
}

.customerPolicyTerms {
  min-height: 100px;
  display: flex;
  align-items: center;
}
.customerPolicyTerms .MuiCircularProgress-colorPrimary {
  color: #137ae7 !important;
  position: inherit;
}
.customerPolicyTerms .MuiButton-label {
  min-width: 128px;
}

.font-bree {
  font-family: "Bree Serif", serif !important;
}

.text-muted {
  color: #788195;
}

.editor-backdrop {
  display: flex;
  position: relative;
  width: 100%;
}
.editor-backdrop-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(47, 52, 68, 0.85);
  opacity: 0.8;
  position: absolute;
  z-index: 1;
}
.editor-backdrop-lock-icon {
  width: 100px;
  height: 100px;
  background-color: white;
  color: black;
  position: relative;
  border-radius: 10px;
  top: 50%;
  left: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.editor-backdrop-lock-icon > svg {
  font-size: 48px;
}
