*::-webkit-input-placeholder {
  color: #bbb !important;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #bbb !important;
  opacity: 1;
  height: we;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #bbb !important;
  opacity: 1;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #bbb !important;
}

*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb !important;
}

*::placeholder {
  /* modern browser */
  color: #bbb !important;
}

.ql-editor {
  padding: 5px 5px;
  font-size: 12px;
}

.ql-toolbar.ql-snow {
  border: none;
  font-family: inherit;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  border: none;
  height: auto;
  padding: 10px 10px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: 6px 6px 10px 6px;
  border-radius: 4px;
}
.ql-container.ql-snow:hover {
  background-color: #e9eef2 !important;
}
.ql-container.ql-snow:hover .ql-editor {
  background-color: #e9eef2 !important;
}
.ql-container.ql-snow a {
  color: #12c4c4;
  text-decoration: underline;
}
.ql-container.ql-snow strong {
  color: #000;
}
